.flex {
    display: flex;
}

.flex-row {
    flex-direction: row;
}

.justify-start {
    justify-content: flex-start;
}

.justify-between {
    justify-content: space-between;
}

.items-center {
    align-items: center;
}