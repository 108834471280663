@use 'src/styles/color-styles';
@use 'src/styles/spacing';

.u-text--black {
  color: color-styles.zonar-getColor(black);
}

.u-text--gray {
  color: color-styles.zonar-getColor(gray);
}

.u-text--blue {
  color: color-styles.zonar-getColor(blue);
}

.u-margin__left--extra-tiny {
  @include spacing.zonar-margin(extra-tiny, left);
}

.u-margin__right--extra-tiny {
  @include spacing.zonar-margin(extra-tiny, right);
}

.u-margin__right--tiny {
  @include spacing.zonar-margin(tiny, right);
}

.u-margin__right--normal {
  @include spacing.zonar-margin(normal, right);
}

.u-margin__right--small {
  @include spacing.zonar-margin(small, right);
}

.u-margin__top--tiny {
  @include spacing.zonar-margin(tiny, top);
}

.u-margin__bottom--none {
  @include spacing.zonar-margin(none, bottom);
}

.u-margin__bottom--tiny {
  @include spacing.zonar-margin(tiny, bottom);
}

.u-margin__bottom--medium {
  @include spacing.zonar-margin(medium, bottom);
}

.u-margin__bottom--large {
  @include spacing.zonar-margin(large, bottom);
}

.u-margin__top--medium {
  @include spacing.zonar-margin(medium, top);
}

.u-margin__top--large {
  @include spacing.zonar-margin(large, top);
}

@media (max-width: 768px) {
  .hide-on-mobile {
    display: none;
  }
}
