@use "@angular/material" as mat;
// pattern library
@import "@zonar-ui/material/zonar-ui-material.theme.scss";
@import "@zonar-ui/sidenav/zonar-ui-sidenav.theme.scss";
@import "@zonar-ui/core/zonar-ui-core.theme.scss";

// material icon scss
@import "material-icons/iconfont/material-icons.scss";

// application themes

@mixin apply-zonar-theme($theme) {
  @include mat.all-component-themes($theme);
  @include zonar-ui-core-theme($theme);
  @include zonar-ui-sidenav-theme($theme)
}

.zonar-default-theme {
  @include apply-zonar-theme($zonar-default-theme);
  background-color: white;
}

.zonar-dark-theme {
  @include apply-zonar-theme($zonar-dark-theme);
  background-color: black;
}

// Fix for the zui-searchable-dropdown, desktop and mobile - the placeholder text should not overlap the search icon
// TODO - Move this into the pattern library
.cdk-overlay-pane {
  .mat-select-search-inner {
    .mat-select-search-input {
      text-indent: 1em;
    }
  }
}

.app-container-desktop {
  .mat-drawer {
    .mat-drawer-inner-container {
      overflow: hidden; // This prevents the leftnav from scrolling
    }
  }
}

// fix for material buttons
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button,
.mat-button-base,
.mat-button-wrapper {
  padding: 0;
  margin: 0;
  line-height: unset;
}

zui-sidenav-header {
  mat-toolbar {
    background: white !important;
    color: black !important;

    mat-icon {
      color: black !important;
    }
  }
}

.tab-width {
  max-width: 800px;
}

.zonar-default-theme .mat-primary .mat-pseudo-checkbox {
  color: #0075b4 !important;
}

.zonar-default-theme .mat-primary .mat-pseudo-checkbox-checked {
  background-color: #0075b4 !important;
}

.zonar-default-theme .mat-focused .mat-form-field-outline {
  color: #0075b4 !important;
}

.zonar-default-theme .mat-focused .mat-form-field-label {
  color: #0075b4 !important;
}

.zonar-default-theme .mat-snack-bar-container {
  color: $black;
  background: $white;
  min-width: 33vw;
  padding: 0;
  min-height: auto;
  box-shadow: 0px 3px 5px -1px rgb(41 41 41 / 20%), 0px 6px 10px 0px rgb(41 41 41 / 14%), 0px 1px 18px 0px rgb(41 41 41 / 12%);
  border: 1px solid $critical;
}

.zonar-default-theme .notification-icon-container {
  background-color: $critical;
}

.zonar-default-theme .notification-content {
  margin-left: 20px;
}

.zonar-default-theme .notification-icon-container .mat-icon {
  color: $white;
}

.zonar-default-theme .notification-content>p {
  margin: 16px 0 1px;
}